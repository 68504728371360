import React, { useState } from 'react';
import Link from 'next/link';
import classnames from 'classnames';
import moment from 'moment-timezone';

import { exceptionDays, Props } from './index';
import StyledComponent from './styles';

const InfoLineNavSection: React.FC<Props> = ({
    translations,
    isScrolledTop,
    isMobile,
    mobileMenu,
    isLandingPage,
}): React.ReactElement => {
    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const daysConfigObj = {
        1: { from: 8, to: 19 },
        2: { from: 8, to: 19 },
        3: { from: 8, to: 19 },
        4: { from: 8, to: 19 },
        5: { from: 8, to: 19 },
        6: { from: 9, to: 14 },
        0: null,
    };

    const isExceptionDay = () => {
        const today = moment().tz('Europe/Warsaw');
        return exceptionDays.some(exception => today.isSame(moment(exception, 'DD.MM.YYYY'), 'day'));
    };

    const formatTimeRange = (from, to) => `${from}:00 - ${to}:00`;

    const getTranslationConfig = (isEnabled: boolean, dayConfig: { from: number, to: number } | null  = null) => {
        const timeLabel = dayConfig ? formatTimeRange(dayConfig?.from, dayConfig?.to) : '';

        // Exception day or no dayConfig
        if (!dayConfig || isExceptionDay()) {
            return {
                translations: {
                    ...translations?.topRightInfoLineDisabled,
                    label: translations?.topRightInfoLineDisabled?.labelDropDown,
                },
                infoLineEnabled: false,
            };
        }

        // Regular days - open/closed status
        if (isEnabled) {
            // Open - check if it's landing page
            return {
                translations: {
                    ...(isLandingPage
                        ? translations?.topRightInfoLineEnabledLandingPage
                        : translations?.topRightInfoLineEnabled),
                    label: timeLabel,
                },
                infoLineEnabled: true,
            };
        } else {
            // Closed - check if it's landing page
            return {
                translations: {
                    ...(isLandingPage
                        ? translations?.topRightInfoLineDisabledLandingPage
                        : translations?.topRightInfoLineDisabled),
                    label: timeLabel,
                },
                infoLineEnabled: false,
            };
        }
    };

    const getInfoLineStatus = () => {
        const currentDate = moment().tz('Europe/Warsaw');
        const currentHour = currentDate.hour();
        const currentDay = currentDate.day();
        const dayConfig = daysConfigObj?.[currentDay];

        // Exception day or closed day
        if (!dayConfig || isExceptionDay()) {
            return getTranslationConfig(false);
        }

        // Check if within operating hours
        const isOpen = currentHour >= dayConfig?.from && currentHour < dayConfig?.to;
        return getTranslationConfig(isOpen, dayConfig);
    };

    const infoLineData = getInfoLineStatus().translations;
    const infoLineIsEnabled = getInfoLineStatus().infoLineEnabled;
    console.log(infoLineData);
    const infoLineClockIcon = (): string => {
        if (infoLineIsEnabled) return '/images/menu/clock-green.svg';
        if (isScrolledTop) return '/images/menu/clock-light.svg';
        return '/images/menu/clock.svg';
    };

    if (mobileMenu) {
        return (
            <StyledComponent className='info-line-nav-wrapper'>
                <a
                    className='info-line-nav'
                    href={infoLineData.buttonProps.href}
                >
                    <div className='info-line-nav-content'>
                        <div
                            className={classnames({
                                'info-line-nav-content-phone': true,
                                'dark-color': !isScrolledTop,
                            })}
                        >
                            {infoLineData.buttonProps.phoneLabel}
                        </div>
                        <div
                            className={classnames({
                                'info-line-nav-content-status': true,
                                active: infoLineIsEnabled,
                            })}
                        >
                            {isMobile ? infoLineData.labelDropDown : infoLineData.label}
                        </div>
                    </div>
                </a>
            </StyledComponent>
        );
    }

    return (
        <StyledComponent className='info-line-nav-wrapper'>
            <Link
                href={infoLineData.buttonProps.href}
                prefetch={false}
            >
                <a
                    className='info-line-nav'
                    onClick={(e) => {
                        if (!mobileMenu) {
                            e.preventDefault();
                            setShowDropDown(!showDropDown);
                        }
                    }}
                    aria-label='infoline'
                >
                    <div className='info-line-nav-icon'>
                        <img
                            src={
                                isScrolledTop
                                    ? infoLineData.iconLight
                                    : infoLineData.icon
                            }
                            alt='info-line'
                            width='18px'
                            height='26px'
                        />
                    </div>
                    {!isMobile && (
                        <div className='info-line-nav-content'>
                            <div
                                className={classnames({
                                    'info-line-nav-content-phone': true,
                                    'dark-color': !isScrolledTop,
                                })}
                            >
                                {infoLineData.buttonProps.phoneLabel}
                            </div>
                            <div
                                className={classnames({
                                    'info-line-nav-content-status': true,
                                    'dark-color': !isScrolledTop,
                                })}
                            >
                                <img
                                    className="info-line-nav-content-icon"
                                    src={infoLineClockIcon()}
                                    alt='info-line'
                                    width='13px'
                                    height='13px'
                                />
                                {infoLineData.label}
                            </div>
                        </div>
                    )}
                </a>
            </Link>
            <div
                className={classnames({
                    'info-line-drop-down': true,
                    active: showDropDown,
                })}
            >
                <p
                    className={classnames({
                        status: true,
                        enabled: infoLineIsEnabled,
                    })}
                >
                    {infoLineData?.labelDropDown}
                </p>
                {infoLineData?.buttonProps?.callLabel && (
                    <Link
                        href={infoLineData.buttonProps.href}
                        prefetch={false}
                    >
                        <a>{infoLineData.buttonProps.callLabel}</a>
                    </Link>
                )}
            </div>
        </StyledComponent>
    );
};

export default InfoLineNavSection;
